var defaultLanguage = "en";
var supportedLanguages = {defaultLanguage:true, "xx":true, "nb": true};


function calculateLanguage() {
  var requestedLanguage = url('?lang');

  if (!requestedLanguage) {
    return defaultLanguage;
  }

  if (supportedLanguages.hasOwnProperty(requestedLanguage)) {
    return requestedLanguage;
  } 

  return defaultLanguage;
}


function redirectByLanguage() {
  language = calculateLanguage();

  var query;

  if (url('query')) {
    query = '?' + url('query');
  } else {
    query = '';
  }

  window.location.href = "index-" + language + ".html" + query;
}

redirectByLanguage();
